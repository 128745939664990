import React from "react";
import { Field } from "react-final-form";
import Wizard from "./Wizard";
import { withTranslation } from "react-multi-lang";
import { Row, Col } from "antd";
import styled from "styled-components";

import Number from "./fields/Number";
import Select from "./fields/Select";

import Header from "./Header";
import Footer from "./Footer";

import FinalStep from "./FinalStep";

import { businessTypeOptions, incomeOptions, valueOptions } from "./options";

import { submitForm } from "./utils";

const Wrapper = styled.div`
    padding-bottom: 50px;
`;

const StyledRow = styled(Row)`
    margin: 10px;
`;

class QuoteOffice extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isComplete: false
        };
    }

    onSubmit = async values => {
        try {
            await submitForm(values, "Protección de oficina, local o almacén");
            this.setState({ isComplete: true });
        } catch (e) {
            alert("error");
        }
    };

    render() {
        const required = value => (value ? undefined : t("quote.required"));

        const { t } = this.props;

        return (
            <Wrapper>
                <Header />

                <StyledRow>
                    <Col xs={{ span: 24, offset: 0 }} lg={{ span: 8, offset: 8 }} sm={{ span: 24, offset: 0 }}>
                        <Wizard onSubmit={this.onSubmit} isComplete={this.state.isComplete}>
                            <Wizard.Page>
                                <Field
                                    name="businessType"
                                    validate={required}
                                    component={Select}
                                    options={businessTypeOptions(t)}
                                    placeholder={t("quote.selectCategory")}
                                    label={t("quote.businessType")}
                                    mode="multiple"
                                />
                            </Wizard.Page>

                            <Wizard.Page>
                                <Field
                                    name="businessIncome"
                                    validate={required}
                                    component={Select}
                                    options={incomeOptions(t)}
                                    placeholder={t("quote.selectOption")}
                                    label={t("quote.businessIncome")}
                                />
                            </Wizard.Page>

                            <Wizard.Page>
                                <Field
                                    name="premisesType"
                                    mode="multiple"
                                    validate={required}
                                    component={Select}
                                    options={[
                                        { value: 1, label: t("quote.premisesType1") },
                                        { value: 2, label: t("quote.premisesType2") },
                                        { value: 3, label: t("quote.premisesType3") }
                                    ]}
                                    placeholder={t("quote.selectOption")}
                                    label={t("quote.premisesType")}
                                />

                                <Field
                                    name="ownerType"
                                    validate={required}
                                    component={Select}
                                    options={[
                                        { value: 1, label: t("quote.ownerType1") },
                                        { value: 2, label: t("quote.ownerType2") }
                                    ]}
                                    placeholder={t("quote.selectOption")}
                                    label={t("quote.ownerType")}
                                />
                                <Field name="size" validate={required} component={Number} type="number" placeholder={t("quote.writeOptionAmount")} label={t("quote.size")} />
                                <Field
                                    name="securityType"
                                    component={Select}
                                    options={[
                                        { value: 1, label: t("quote.securityType1") },
                                        { value: 2, label: t("quote.securityType2") },
                                        { value: 3, label: t("quote.securityType3") }
                                    ]}
                                    label={t("quote.securityType")}
                                    placeholder={t("quote.selectOption")}
                                    mode="multiple"
                                />
                            </Wizard.Page>

                            <Wizard.Page>
                                <Field
                                    name="contentsType"
                                    validate={required}
                                    component={Select}
                                    options={[
                                        { value: 1, label: t("quote.contentsType1") },
                                        { value: 2, label: t("quote.contentsType2") }
                                    ]}
                                    label={t("quote.contentsType")}
                                    placeholder={t("quote.selectOption")}
                                    mode="multiple"
                                />
                                <Field name="contents" component={Select} options={valueOptions(t)} label={t("quote.contents1")} placeholder={t("quote.selectOption")} />
                                <Field name="contents2" component={Select} options={valueOptions(t)} label={t("quote.contents2")} placeholder={t("quote.selectOption")} />
                                <Field name="contents3" component={Select} options={valueOptions(t)} label={t("quote.contents3")} placeholder={t("quote.selectOption")} />
                            </Wizard.Page>

                            <Wizard.Page>
                                <FinalStep />
                            </Wizard.Page>
                        </Wizard>
                    </Col>
                </StyledRow>
                <Footer />
            </Wrapper>
        );
    }
}

export default withTranslation(QuoteOffice);
