import React from "react";
import { Form, Input, Typography } from "antd";
import styled from "styled-components";

const { Title, Text } = Typography;

const StyledTitle = styled(Title)`
    white-space: normal;
    word-break: break-all;
`;

const FormItem = styled(Form.Item)`
    flex-direction: column;
    margin-bottom: 14px;
`;

const TextFieldWrapper = ({ input, label, placeholder, extra, small, meta: { error, modified, submitFailed } }) => {
    let fieldStatus = "success";

    if (modified || submitFailed) {
        if (error && error.length > 0) {
            fieldStatus = "error";
        } else {
            fieldStatus = "success";
        }
    }

    // ant is stupid
    var inputProps = {};
    if (input.value) {
        inputProps.value = input.value;
    }

    return (
        <FormItem
            label={small ? <Text strong>{label}</Text> : <StyledTitle level={5}>{label}</StyledTitle>}
            validateStatus={fieldStatus}
            help={fieldStatus === "error" ? error : null}
            extra={extra ? extra : null}
        >
            <Input style={{ width: "100%" }} placeholder={placeholder} onChange={input.onChange} {...inputProps} />
        </FormItem>
    );
};

export default TextFieldWrapper;
