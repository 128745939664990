import React from "react";
import { Field } from "react-final-form";
import Wizard from "./Wizard";
import { withTranslation } from "react-multi-lang";
import { Row, Col, Typography, Card, List, Button, Modal } from "antd";
import styled from "styled-components";

import TextField from "./fields/TextField";
import Checkbox from "./fields/Checkbox";

import Header from "./Header";
import Footer from "./Footer";

import { submitForm } from "./utils";

const { Title } = Typography;

const Wrapper = styled.div`
    padding-bottom: 50px;
    overflow: hidden;
`;

const StyledRow = styled(Row)`
    margin: 10px;
    overflow: hidden !important;
`;

const CustomListItem = styled(List.Item)`
    padding: 2px 16px !important;
`;

class FormLegal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            packType: null,
            isComplete: false,
            modalOpen: false
        };
    }

    onSubmit = async values => {
        window.values = values;
        try {
            await submitForm(values, "Protección Jurídica");
            this.setState({ isComplete: true });
        } catch (e) {
            alert("error");
        }
    };

    setModalVisible(val) {
        this.setState({ modalOpen: val });
    }

    setPack(pack) {
        this.setState({ packType: pack });
    }

    customPackItems(limit = 6) {
        let options = [];
        for (let i = 1; i <= limit; i += 1) {
            options.push(<CustomListItem>{this.props.t(`quote.packPersonalised${i}`)}</CustomListItem>);
        }

        return options;
    }
    render() {
        const required = value => (value ? undefined : t("quote.required"));

        const { t } = this.props;
        return (
            <Wrapper>
                <Header />
                {!this.state.packType ? (
                    <>
                        <StyledRow gutter={12}>
                            <Col xs={{ span: 24 }} lg={{ span: 6, offset: 3 }} sm={{ span: 24 }}>
                                <Card title={t("quote.packType1")} bordered={false}>
                                    <List bordered size="small">
                                        <CustomListItem>{t("quote.legalPhoneAssistance")}</CustomListItem>
                                        <CustomListItem>{t("quote.claimDamages")}</CustomListItem>
                                        <CustomListItem>{t("quote.premisesRights")}</CustomListItem>
                                        <CustomListItem>{t("quote.defenseCriminalLiability")}</CustomListItem>
                                        <CustomListItem>{t("quote.claimInsuranceContracts")}</CustomListItem>
                                        <CustomListItem>{t("quote.claimSupplyContracts")}</CustomListItem>
                                        <CustomListItem>{t("quote.administrativeMatters")}</CustomListItem>
                                    </List>
                                    <br />
                                    <table>
                                        <thead>
                                            <th colspan="3">{t("quote.employeesPremium")}</th>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>
                                                    <b>0-5</b>
                                                </td>
                                                <td>
                                                    <b>6-10</b>
                                                </td>
                                                <td>
                                                    <b>11-20</b>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>51,50€</td>
                                                <td>93,14€</td>
                                                <td>194,09€</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <br />
                                    <Button className="visible-sm visible-xs" onClick={() => this.setPack(1)} type="primary" htmlType="button" block>
                                        {t("quote.select")}
                                    </Button>
                                </Card>
                            </Col>
                            <Col xs={{ span: 24 }} lg={{ span: 6 }} sm={{ span: 24 }}>
                                <Card title={t("quote.packType2")} bordered={false}>
                                    <List bordered size="small">
                                        <CustomListItem>{t("quote.legalPhoneAssistance")}</CustomListItem>
                                        <CustomListItem>{t("quote.claimDamages")}</CustomListItem>
                                        <CustomListItem>{t("quote.premisesRights")}</CustomListItem>
                                        <CustomListItem>{t("quote.defenseCriminalLiability")}</CustomListItem>
                                        <CustomListItem>{t("quote.claimInsuranceContracts")}</CustomListItem>
                                        <CustomListItem>{t("quote.claimSupplyContracts")}</CustomListItem>
                                        <CustomListItem>{t("quote.administrativeMatters")}</CustomListItem>
                                        <CustomListItem>{t("quote.invoiceReclamation")}</CustomListItem>
                                    </List>
                                    <br />
                                    <table>
                                        <thead>
                                            <th colspan="3">{`${t("quote.employeesPremium")} (${t("quote.until")})`}</th>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>
                                                    <b>0-5</b>
                                                </td>
                                                <td>
                                                    <b>6-10</b>
                                                </td>
                                                <td>
                                                    <b>11-20</b>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td> 151,03€</td>
                                                <td> 229,66€</td>
                                                <td>387,01€</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <br />
                                    <Button className="visible-sm visible-xs" onClick={() => this.setPack(2)} type="primary" htmlType="button" block>
                                        {t("quote.select")}
                                    </Button>
                                </Card>
                            </Col>
                            <Col xs={{ span: 24 }} lg={{ span: 6 }} sm={{ span: 24 }}>
                                <Card title={t("quote.packType3")} bordered={false}>
                                    <List bordered size="small">
                                        {this.customPackItems()}
                                    </List>
                                    <br />
                                    <Button type="secondary" onClick={() => this.setModalVisible(true)} htmlType="button" block>
                                        {t("quote.showMore")}
                                    </Button>

                                    <Button className="visible-sm visible-xs" style={{ marginTop: 10 }} onClick={() => this.setPack(3)} type="primary" htmlType="button" block>
                                        {t("quote.select")}
                                    </Button>
                                </Card>
                            </Col>
                        </StyledRow>
                        <StyledRow className="hidden-sm hidden-xs" gutter={12}>
                            <Col xs={{ span: 24 }} lg={{ span: 6, offset: 3 }} sm={{ span: 24 }}>
                                <Button onClick={() => this.setPack(3)} type="primary" htmlType="button" block>
                                    {t("quote.select")}
                                </Button>
                            </Col>
                            <Col xs={{ span: 24 }} lg={{ span: 6 }} sm={{ span: 24 }}>
                                <Button onClick={() => this.setPack(3)} type="primary" htmlType="button" block>
                                    {t("quote.select")}
                                </Button>
                            </Col>
                            <Col xs={{ span: 24 }} lg={{ span: 6 }} sm={{ span: 24 }}>
                                <Button onClick={() => this.setPack(3)} type="primary" htmlType="button" block>
                                    {t("quote.select")}
                                </Button>
                            </Col>
                        </StyledRow>
                    </>
                ) : (
                    <StyledRow>
                        <Col xs={{ span: 24, offset: 0 }} lg={{ span: 8, offset: 8 }} sm={{ span: 24, offset: 0 }}>
                            <Wizard hideWizard onSubmit={this.onSubmit} isComplete={this.state.isComplete} initialValues={{ packType: this.state.packType }}>
                                <Wizard.Page>
                                    <Field name="customerName" validate={required} component={TextField} type="text" placeholder={t("quote.writeOptionName")} label={t("quote.customerName")} small />
                                    <Field name="fiscalId" validate={required} component={TextField} type="text" placeholder={t("quote.writeOptionNIF")} label={t("quote.fiscalId")} small />
                                    <Field name="businessName" component={TextField} type="text" placeholder={t("quote.writeOptionCompanyName")} label={t("quote.businessName")} small />
                                    <Field name="telephone" validate={required} component={TextField} type="text" placeholder={t("quote.writeOptionPhone")} label={t("quote.telephone")} small />
                                    <Field name="email" validate={required} component={TextField} type="email" placeholder={t("quote.writeOptionEmail")} label={t("quote.email")} small />

                                    {this.state.packType === 3 && (
                                        <>
                                            <Title level={4}>{t("quote.options")}</Title>
                                            {Array(27)
                                                .fill(0)
                                                .map((el, i) => (i > 0 ? <Field name={`packPersonalised${i}`} component={Checkbox} type="checkbox" label={t(`quote.packPersonalised${i}`)} /> : null))}
                                        </>
                                    )}
                                </Wizard.Page>
                            </Wizard>
                        </Col>
                    </StyledRow>
                )}

                <Footer />

                <Modal footer={null} style={{ top: 20 }} width="1000" centered visible={this.state.modalOpen} onOk={() => this.setModalVisible(false)} onCancel={() => this.setModalVisible(false)}>
                    <List size="small">{this.customPackItems(27)}</List>
                </Modal>
            </Wrapper>
        );
    }
}

export default withTranslation(FormLegal);
