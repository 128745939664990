import React from "react";
import { Field } from "react-final-form";
import Wizard from "../Wizard";
import { withTranslation } from "react-multi-lang";
import { Row, Col, Typography } from "antd";
import styled from "styled-components";

import TextField from "../fields/TextField";
import Checkbox from "../fields/Checkbox";
import DatePicker from "../fields/DatePicker";

import Header from "../Header";
import Footer from "../Footer";

import { submitForm } from "../utils";

const { Title, Paragraph } = Typography;

const Wrapper = styled.div`
    padding-bottom: 50px;
`;

const StyledRow = styled(Row)`
    margin: 10px;
`;

class FormIncapacity extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isComplete: false
        };
    }

    onSubmit = async values => {
        window.values = values;
        try {
            await submitForm(values, "Autonomo - Seguro de Incapacidad laboral temporal");
            this.setState({ isComplete: true });
        } catch (e) {
            alert("error");
        }
    };

    render() {
        const required = value => (value ? undefined : t("quote.required"));

        const { t } = this.props;
        return (
            <Wrapper>
                <Header />

                <StyledRow>
                    <Col xs={{ span: 24, offset: 0 }} lg={{ span: 8, offset: 8 }} sm={{ span: 24, offset: 0 }}>
                        <Wizard hideWizard onSubmit={this.onSubmit} isComplete={this.state.isComplete} initialValues={{ autonomous: false }}>
                            <Wizard.Page>
                                <Title level={4}>{t("quote.incapacity")}</Title>
                                <Paragraph>{t("quote.incapacityExplanation")}</Paragraph>
                                <Field name="customerName" validate={required} component={TextField} type="text" placeholder={t("quote.writeOptionName")} label={t("quote.customerName")} small />
                                <Field name="fiscalId" validate={required} component={TextField} type="text" placeholder={t("quote.writeOptionNIF")} label={t("quote.fiscalId")} small />
                                <Field name="telephone" validate={required} component={TextField} type="text" placeholder={t("quote.writeOptionPhone")} label={t("quote.telephone")} small />
                                <Field name="profession" validate={required} component={TextField} type="text" placeholder={t("quote.writeOptionProfession")} label={t("quote.profession")} small />
                                <Field name="email" validate={required} component={TextField} type="email" placeholder={t("quote.writeOptionEmail")} label={t("quote.email")} small />
                                <Field name="autonomous" component={Checkbox} type="checkbox" label={t("quote.autonomous")} />
                                <Field
                                    name="policyholderDateOfBirth"
                                    validate={required}
                                    component={DatePicker}
                                    placeholder={t("quote.writeOptionDateOfBirth")}
                                    label={t("quote.policyholderDateOfBirth")}
                                    small
                                />
                                <Field name="postCode" validate={required} component={TextField} type="text" placeholder={t("quote.writeOptionPostCode")} label={t("quote.postCode")} small />
                                <Field name="province" validate={required} component={TextField} type="text" placeholder={t("quote.writeOptionProvince")} label={t("quote.province")} small />
                            </Wizard.Page>
                        </Wizard>
                    </Col>
                </StyledRow>
                <Footer />
            </Wrapper>
        );
    }
}

export default withTranslation(FormIncapacity);
