import React from "react";
import { DatePicker, Form, Typography } from "antd";
import styled from "styled-components";
import moment from "moment";

const { Title, Text } = Typography;

const StyledTitle = styled(Title)`
    white-space: normal;
    word-break: break-all;
`;

const FormItem = styled(Form.Item)`
    flex-direction: column;
    margin-bottom: 14px;
`;

const DatePickerWrapper = ({ input, label, placeholder, extra, small, meta: { error, modified, submitFailed } }) => {
    let fieldStatus = "success";

    if (modified || submitFailed) {
        if (error && error.length > 0) {
            fieldStatus = "error";
        } else {
            fieldStatus = "success";
        }
    }

    // ant is stupid
    var inputProps = {};
    if (input.value) {
        inputProps.value = moment(input.value, "YYYY-MM-DD");
    }

    function onChange(date) {
        input.onChange(date ? moment(date).format("YYYY-MM-DD") : null);
    }

    return (
        <FormItem
            label={small ? <Text strong>{label}</Text> : <StyledTitle level={5}>{label}</StyledTitle>}
            validateStatus={fieldStatus}
            help={fieldStatus === "error" ? error : null}
            extra={extra ? extra : null}
        >
            <DatePicker format="DD/MM/YYYY" style={{ width: "100%" }} placeholder={placeholder} onChange={onChange} {...inputProps} />
        </FormItem>
    );
};

export default DatePickerWrapper;
