import React from "react";
import styled from "styled-components";

import { withRouter } from "react-router-dom";

import logo from "../logo.png";

const TopBar = styled.div`
    background-color: #ffa534;
    position: fixed;
    top: 0;
    width: 100%;
    height: 15px;
    z-index: 100;
`;

const Logo = styled.img`
    display: block;
    margin: 0 auto;
    cursor: pointer;
`;

function Header(props) {
    return (
        <>
            <TopBar />

            <Logo src={logo} height="200" alt="Segure Commerce" onClick={() => props.history.push("/")} />
        </>
    );
}

export default withRouter(Header);
