import _ from "lodash";

export default async function fetcher(url, options = {}) {
    const defaultOptions = {
        headers: { "Content-Type": "application/json" }
    };

    const fetchOptions = _.merge(defaultOptions, options);

    const res = await fetch(`https://admin.attis.es/api/${url}`, fetchOptions)
        .then(handleErrors)
        .then(response => response.json())
        .catch(error => console.log(error));

    return res;
}

function handleErrors(response) {
    if (!response.ok) {
        throw Error(response.statusText);
    }
    return response;
}
