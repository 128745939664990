import React, { Component } from "react";
import { Route, Switch, HashRouter, Redirect } from "react-router-dom";
import "./App.less";
import * as serviceWorker from "./serviceWorker";

import Selector from "./components/Selector";
import FormCivil from "./components/FormCivil";
import FormOffice from "./components/FormOffice";
import FormCyber from "./components/FormCyber";
import AutonomousSelector from "./components/autonomous/Selector";
import AutonomousHealth from "./components/autonomous/FormHealth";
import AutonomousIncapacity from "./components/autonomous/FormIncapacity";
import AutonomousLife from "./components/autonomous/FormLife";
import AutonomousPensions from "./components/autonomous/FormPensions";
import FormLegal from "./components/FormLegal";

import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";

library.add(fas);

class App extends Component {
    render() {
        return (
            <HashRouter>
                <Switch>
                    <Route exact path="/" component={Selector} />
                    <Route exact path="/civil" component={FormCivil} />
                    <Route exact path="/office" component={FormOffice} />
                    <Route exact path="/cybersecurity" component={FormCyber} />
                    <Route exact path="/autonomous" component={AutonomousSelector} />
                    <Route exact path="/autonomous/health" component={AutonomousHealth} />
                    <Route exact path="/autonomous/incapacity" component={AutonomousIncapacity} />
                    <Route exact path="/autonomous/life" component={AutonomousLife} />
                    <Route exact path="/autonomous/pensions" component={AutonomousPensions} />
                    <Route exact path="/legal" component={FormLegal} />
                    <Redirect to="/" />
                </Switch>
            </HashRouter>
        );
    }
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();

export default App;
