import React from "react";
import { Form, Checkbox } from "antd";
import styled from "styled-components";

const FormItem = styled(Form.Item)`
    flex-direction: column;
    margin-bottom: 4px;
`;

const CheckboxWrapper = ({ input, label, extra, meta: { error, modified, submitFailed } }) => {
    let fieldStatus = "success";

    if (modified || submitFailed) {
        if (error && error.length > 0) {
            fieldStatus = "error";
        } else {
            fieldStatus = "success";
        }
    }

    
    return (
        <FormItem validateStatus={fieldStatus} help={fieldStatus === "error" ? error : null} extra={extra ? extra : null}>
            <Checkbox checked={input.checked} onChange={input.onChange}>
                {label}
            </Checkbox>
        </FormItem>
    );
};
export default CheckboxWrapper;
