import React from "react";
import { Form, Select, Typography } from "antd";
import styled from "styled-components";

const { Title, Text } = Typography;
const { Option } = Select;

const StyledTitle = styled(Title)`
    white-space: normal;
    word-break: break-all;
`;

const FormItem = styled(Form.Item)`
    flex-direction: column;
    margin-bottom: 14px;
`;

const CustomSelect = styled.div`
    ${props =>
        props.fieldStatus === "error" &&
        `border-radius: 2px;
        padding: 4px;
        border: solid 1.75px #ff4d4f !important;
        `};
`;

const isMobile = () => {
    if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
        return true;
    }

    return false;
};

const SelectWrapper = ({ input, label, placeholder, options, extra, small, mode, meta: { error, modified, submitFailed } }) => {
    let fieldStatus = "success";

    if (modified || submitFailed) {
        if (error && error.length > 0) {
            fieldStatus = "error";
        } else {
            fieldStatus = "success";
        }
    }

    // ant is stupid
    var inputProps = {};
    if (input.value) {
        inputProps.value = input.value;
    }

    return (
        <FormItem
            label={small ? <Text strong>{label}</Text> : <StyledTitle level={5}>{label}</StyledTitle>}
            validateStatus={fieldStatus}
            help={fieldStatus === "error" ? error : null}
            extra={extra ? extra : null}
        >
            {isMobile() ? (
                <CustomSelect fieldStatus={fieldStatus}>
                    <select value={input.value} onChange={input.onChange} style={{ width: "100%" }} multiple={mode === "multiple"}>
                        <option key={0} disabled selected value>
                            {placeholder}
                        </option>
                        {options.map(option => {
                            return (
                                <option key={option.value} value={option.value}>
                                    {option.label}
                                </option>
                            );
                        })}
                    </select>
                </CustomSelect>
            ) : (
                <Select
                    allowClear
                    showSearch
                    style={{ width: "100%" }}
                    placeholder={placeholder}
                    optionFilterProp="children"
                    onChange={input.onChange}
                    filterOption={(input, option) => option.children.toString().toLowerCase().indexOf(input.toLowerCase()) >= 0}
                    mode={mode}
                    {...inputProps}
                >
                    {options.map(option => {
                        return (
                            <Option key={option.value} value={option.value}>
                                {option.label}
                            </Option>
                        );
                    })}
                </Select>
            )}
        </FormItem>
    );
};

export default SelectWrapper;
