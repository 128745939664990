import React from "react";
import { withTranslation } from "react-multi-lang";
import { Row, Col, Button } from "antd";
import styled from "styled-components";

import Header from "../Header";
import Footer from "../Footer";

const Wrapper = styled.div`
    padding-bottom: 50px;
`;

const StyledRow = styled(Row)`
    margin: 10px;
`;

class Selector extends React.Component {
    render() {
        const { t } = this.props;
        return (
            <Wrapper>
                <Header />

                <StyledRow>
                    <Col xs={{ span: 24, offset: 0 }} lg={{ span: 8, offset: 8 }} sm={{ span: 24, offset: 0 }}>
                        <Button type="primary" block onClick={() => this.props.history.push("/autonomous/health")}>
                            {t("selector.autonomousHealth")}
                        </Button>

                        <Button type="primary" style={{ marginTop: 10 }} onClick={() => this.props.history.push("/autonomous/incapacity")} block>
                            {t("selector.autonomousIncapacity")}
                        </Button>

                        <Button type="primary" style={{ marginTop: 10 }} onClick={() => this.props.history.push("/autonomous/life")} block>
                            {t("selector.autonomousLife")}
                        </Button>

                        <Button type="primary" style={{ marginTop: 10 }} onClick={() => this.props.history.push("/autonomous/pensions")} block>
                            {t("selector.autonomousPensions")}
                        </Button>
                    </Col>
                </StyledRow>
                <Footer />
            </Wrapper>
        );
    }
}

export default withTranslation(Selector);
