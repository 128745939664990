import React from "react";
import { Field } from "react-final-form";
import { withTranslation } from "react-multi-lang";
import { Typography } from "antd";
import styled from "styled-components";

import TextField from "./fields/TextField";

const { Text } = Typography;

const CustomText = styled(Text)`
    margin-top: 15px;
    margin-bottom: 15px;
    display: block;
`;
class FinalStep extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        const required = value => (value ? undefined : t("quote.required"));

        const { t } = this.props;
        return (
            <>
                <CustomText level={4}>{t("quote.contactMessage")}</CustomText>
                <Field name="customerName" validate={required} component={TextField} type="text" placeholder={t("quote.writeOptionName")} label={t("quote.customerName")} small />
                <Field name="businessName" component={TextField} type="text" placeholder={t("quote.writeOptionCompanyName")} label={t("quote.businessName")} small />
                <Field name="fiscalId" validate={required} component={TextField} type="text" placeholder={t("quote.writeOptionNIF")} label={t("quote.fiscalId")} small />
                <Field name="telephone" validate={required} component={TextField} type="text" placeholder={t("quote.writeOptionPhone")} label={t("quote.telephone")} small />
                <Field name="email" validate={required} component={TextField} type="email" placeholder={t("quote.writeOptionEmail")} label={t("quote.email")} small />
            </>
        );
    }
}

export default withTranslation(FinalStep);
