import React from "react";
import { Field } from "react-final-form";
import Wizard from "../Wizard";
import { withTranslation } from "react-multi-lang";
import { Row, Col, Typography } from "antd";
import styled from "styled-components";
import { FieldArray } from "react-final-form-arrays";

import Select from "../fields/Select";
import TextField from "../fields/TextField";
import Checkbox from "../fields/Checkbox";
import DatePicker from "../fields/DatePicker";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import Header from "../Header";
import Footer from "../Footer";

import { submitForm } from "../utils";

const { Title, Paragraph, Text } = Typography;

const Wrapper = styled.div`
    padding-bottom: 50px;
`;

const StyledRow = styled(Row)`
    margin: 10px;
`;

const PulseIcon = styled(FontAwesomeIcon)`
    & {
        animation: fa-beat 5s ease infinite;
    }
    @keyframes fa-beat {
        0% {
            transform: scale(1);
        }
        5% {
            transform: scale(1.25);
        }
        20% {
            transform: scale(1);
        }
        30% {
            transform: scale(1);
        }
        35% {
            transform: scale(1.25);
        }
        50% {
            transform: scale(1);
        }
        55% {
            transform: scale(1.25);
        }
        70% {
            transform: scale(1);
        }
    }
`;

class FormHealth extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isComplete: false
        };
    }

    onSubmit = async values => {
        window.values = values;
        try {
            await submitForm(values, "Autonomo - Seguro de Salud");
            this.setState({ isComplete: true });
        } catch (e) {
            alert("error");
        }
    };

    render() {
        const required = value => (value ? undefined : t("quote.required"));

        const { t } = this.props;
        return (
            <Wrapper>
                <Header />

                <StyledRow>
                    <Col xs={{ span: 24, offset: 0 }} lg={{ span: 8, offset: 8 }} sm={{ span: 24, offset: 0 }}>
                        <Wizard hideWizard onSubmit={this.onSubmit} isComplete={this.state.isComplete} initialValues={{ outOfHospitalCare: false, hospitalCare: false, dentalCover: false }}>
                            <Wizard.Page>
                                <Title level={4}>{t("quote.health")}</Title>
                                <Paragraph>{t("quote.healthExplanation")}</Paragraph>
                                <Field name="customerName" validate={required} component={TextField} type="text" placeholder={t("quote.writeOptionName")} label={t("quote.customerName")} small />
                                <Field name="fiscalId" validate={required} component={TextField} type="text" placeholder={t("quote.writeOptionNIF")} label={t("quote.fiscalId")} small />
                                <Field name="telephone" validate={required} component={TextField} type="text" placeholder={t("quote.writeOptionPhone")} label={t("quote.telephone")} small />
                                <Field name="email" validate={required} component={TextField} type="email" placeholder={t("quote.writeOptionEmail")} label={t("quote.email")} small />
                                <Field
                                    name="coverType"
                                    component={Select}
                                    validate={required}
                                    options={[
                                        { value: 1, label: t("quote.coverType1") },
                                        { value: 2, label: t("quote.coverType2") }
                                    ]}
                                    label={t("quote.coverType")}
                                    placeholder={t("quote.selectOption")}
                                />
                                <Field name="outOfHospitalCare" component={Checkbox} type="checkbox" label={t("quote.outOfHospitalCare")} />
                                <Field name="hospitalCare" component={Checkbox} type="checkbox" label={t("quote.hospitalCare")} />
                                <Field name="dentalCover" component={Checkbox} type="checkbox" label={t("quote.dentalCover")} />

                                <Title level={4}>{t("quote.policyholders")}</Title>

                                <FieldArray name="policyholders">
                                    {({ fields }) => (
                                        <>
                                            {fields.map((policyholder, index) => (
                                                <div key={index}>
                                                    <Text strong style={{ fontSize: 16, paddingBottom: 100 }} type="secondary">
                                                        {t("quote.policyholder") + " #" + parseInt(index + 1)}{" "}
                                                        <FontAwesomeIcon
                                                            title={t("quote.policyholderRemove")}
                                                            style={{ float: "right", cursor: "pointer" }}
                                                            icon="minus"
                                                            onClick={() => fields.remove(index)}
                                                        />
                                                    </Text>
                                                    <br />
                                                    <br />
                                                    <Field
                                                        name={`${policyholder}.policyholderSex`}
                                                        component={Select}
                                                        validate={required}
                                                        options={[
                                                            { value: 1, label: t("quote.policyholderSex1") },
                                                            { value: 2, label: t("quote.policyholderSex2") }
                                                        ]}
                                                        label={t("quote.policyholderSex")}
                                                        placeholder={t("quote.selectOption")}
                                                        small
                                                    />
                                                    <Field
                                                        name={`${policyholder}.policyholderDateOfBirth`}
                                                        validate={required}
                                                        component={DatePicker}
                                                        placeholder={t("quote.writeOptionDateOfBirth")}
                                                        label={t("quote.policyholderDateOfBirth")}
                                                        small
                                                    />
                                                </div>
                                            ))}

                                            <Text
                                                strong
                                                style={{ cursor: "pointer", fontSize: 16 }}
                                                onClick={() =>
                                                    fields.push({
                                                        policyholderSex: 1,
                                                        policyholderDateOfBirth: ""
                                                    })
                                                }
                                            >
                                                <PulseIcon icon="plus" title={t("quote.policyholderAdd")} /> {t("quote.policyholderAdd")}
                                            </Text>
                                        </>
                                    )}
                                </FieldArray>
                                <br />
                                <br />
                            </Wizard.Page>
                        </Wizard>
                    </Col>
                </StyledRow>
                <Footer />
            </Wrapper>
        );
    }
}

export default withTranslation(FormHealth);
