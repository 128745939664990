import React from "react";
import styled from "styled-components";

const BottomBar = styled.div`
    background-color: #ffa534;
    position: fixed;
    bottom: 0;
    width: 100%;
    height: 15px;
    z-index: 100;
`;

function Footer(props) {
    return (
        <>
            <BottomBar />
        </>
    );
}

export default Footer;
