import fetcher from "../lib/fetcher";

export function buildQuote(body) {
    const fields = {
        businessType: {
            label: "Tipo de negocio",
            values: {
                1: "Accesorios Coches y motos",
                2: "Construcción y bricolaje",
                3: "Moda",
                4: "Floristería",
                5: "Libros",
                6: "Material escolar y de oficina",
                7: "Electrónica e informática",
                8: "Droguería y cosmética",
                9: "Metales preciosos",
                10: "Energía",
                11: "Artículos eróticos",
                12: "Fotografía e imprenta",
                13: "Jardinería",
                14: "Regalos",
                15: "Menaje y electrodomésticos",
                16: "Ocio y coleccionismo",
                17: "Disfraces",
                18: "Equipaje y marroquinería",
                19: "Alimentación",
                20: "Salud y bienestar",
                21: "Muebles y decoración",
                22: "Telecomunicaciones",
                23: "Cine y música",
                24: "Ópticas",
                25: "Viajes y alojamiento",
                26: "Billetes y entradas",
                27: "Bisutería, Joyería y Relojes",
                28: "Zapaterías",
                29: "Jugueterías y puericultura",
                30: "Deporte",
                31: "Artículos para mascotas"
            }
        },
        businessIncome: {
            label: "Facturación anual",
            values: {
                1: "0 €",
                2: "10 000 €",
                3: "20 000 €",
                4: "30 000 €",
                5: "40 000 €",
                6: "50 000 €",
                7: "60 000 €",
                8: "70 000 €",
                9: "80 000 €",
                10: "90 000 €",
                11: "100 000 €",
                12: "110 000 €",
                13: "120 000 €",
                14: "130 000 €",
                15: "140 000 €",
                16: "150 000 €",
                17: "160 000 €",
                18: "170 000 €",
                19: "180 000 €",
                20: "190 000 €",
                21: "200 000 €",
                22: "210 000 €",
                23: "220 000 €",
                24: "230 000 €",
                25: "240 000 €",
                26: "250 000 €",
                27: "260 000 €",
                28: "270 000 €",
                29: "280 000 €",
                30: "290 000 €",
                31: "300 000 €",
                32: "310 000 €",
                33: "320 000 €",
                34: "330 000 €",
                35: "340 000 €",
                36: "350 000 €",
                37: "360 000 €",
                38: "370 000 €",
                39: "380 000 €",
                40: "390 000 €",
                41: "400 000 €",
                42: "410 000 €",
                43: "420 000 €",
                44: "430 000 €",
                45: "440 000 €",
                46: "450 000 €",
                47: "460 000 €",
                48: "470 000 €",
                49: "480 000 €",
                50: "490 000 €",
                51: "500 000 €",
                52: "510 000 €",
                53: "520 000 €",
                54: "530 000 €",
                55: "540 000 €",
                56: "550 000 €",
                57: "560 000 €",
                58: "570 000 €",
                59: "580 000 €",
                60: "590 000 €",
                61: "600 000 €",
                62: "610 000 €",
                63: "620 000 €",
                64: "630 000 €",
                65: "640 000 €",
                66: "650 000 €",
                67: "660 000 €",
                68: "670 000 €",
                69: "680 000 €",
                70: "690 000 €",
                71: "700 000 €",
                72: "710 000 €",
                73: "720 000 €",
                74: "730 000 €",
                75: "740 000 €",
                76: "750 000 €",
                77: "760 000 €",
                78: "770 000 €",
                79: "780 000 €",
                80: "790 000 €",
                81: "800 000 €",
                82: "810 000 €",
                83: "820 000 €",
                84: "830 000 €",
                85: "840 000 €",
                86: "850 000 €",
                87: "860 000 €",
                88: "870 000 €",
                89: "880 000 €",
                90: "890 000 €",
                91: "900 000 €",
                92: "910 000 €",
                93: "920 000 €",
                94: "930 000 €",
                95: "940 000 €",
                96: "950 000 €",
                97: "960 000 €",
                98: "970 000 €",
                99: "980 000 €",
                100: "990 000 €",
                101: "1 000 000 €",
                102: "Mas de 1 000 000 €"
            }
        },
        clientsUSACanda: {
            label: "Clientes en USA o Canadá"
        },
        numberEmployees: {
            label: "Cantidad de empleados",
            values: {
                1: "0",
                2: "1",
                3: "2",
                4: "3",
                5: "4",
                6: "5",
                7: "6",
                8: "7",
                9: "8",
                10: "9",
                11: "10",
                12: "11",
                13: "12",
                14: "13",
                15: "14",
                16: "15",
                17: "16",
                18: "17",
                19: "18",
                20: "19",
                21: "20",
                22: "Mas de 20"
            }
        },
        accidentLiability: {
            label: "Responsabilidad civil por Accidente de Trabajo"
        },
        professionalLiability: {
            label: "Responsabilidad Civil Locativa"
        },
        dataLiability: {
            label: "Responsabilidad civil Protección de Datos"
        },
        basicGuarantee: {
            label: "Garantía básica",
            values: {
                1: "150 000 €",
                2: "300 000 €",
                3: "600 000 €",
                4: "1 000 000 €",
                5: "1 500 000 €"
            }
        },
        basicGuaranteeData: {
            label: "Garantía básica datos",
            values: {
                1: "150 000 €",
                2: "300 000 €",
                3: "450 000 €",
                4: "600 000 €",
                5: "1 000 000 €",
                6: "1 500 000 €",
                7: "2 00 000 €"
            }
        },
        websiteSize: {
            label: "Cantidad paginas web",
            values: {
                1: "0-1",
                2: "2",
                3: ">3"
            }
        },
        antiVirus: {
            label: "AntiVirus actualizado"
        },
        backup: {
            label: "Copias de seguridad"
        },
        cardProcessing: {
            label: "Procesa los datos de la tarjeta de pago"
        },
        intrusion: {
            label: "Ha tenido intrusión"
        },
        interuption: {
            label: "Pérdida o interrupción del negocio"
        },
        extortion: {
            label: "Ciberextorsión y fraude cibernético"
        },
        premisesType: {
            label: "Tipo local",
            values: {
                1: "Local comercial",
                2: "Oficina",
                3: "Almacén"
            }
        },
        ownerType: {
            label: "Propiedad o alquiler",
            values: {
                1: "Propiedad",
                2: "Alquiler"
            }
        },
        size: {
            label: "Superficie (m2)"
        },
        securityType: {
            label: "Medidas de seguridad",
            values: {
                1: "Alarma",
                2: "Puertas y otros huecos protegidos",
                3: "Protección contra incendios"
            }
        },
        contentsType: {
            label: "Continente a asegurar",
            values: {
                1: " Valor total",
                2: "Primer riesgo"
            }
        },
        contents: {
            label: "Mobiliario",
            values: {
                1: "N/A",
                2: "10 000 €",
                3: "20 000 €",
                4: "30 000 €",
                5: "40 000 €",
                6: "50 000 €",
                7: "60 000 €",
                8: "70 000 €",
                9: "80 000 €",
                10: "90 000 €",
                11: "100 000 €",
                12: "110 000 €",
                13: "120 000 €",
                14: "130 000 €",
                15: "140 000 €",
                16: "150 000 €",
                17: "160 000 €",
                18: "170 000 €",
                19: "180 000 €",
                20: "190 000 €",
                21: "200 000 €"
            }
        },
        contents2: {
            label: "Ordenadores y equipos electrónicos",
            values: {
                1: "N/A",
                2: "10 000 €",
                3: "20 000 €",
                4: "30 000 €",
                5: "40 000 €",
                6: "50 000 €",
                7: "60 000 €",
                8: "70 000 €",
                9: "80 000 €",
                10: "90 000 €",
                11: "100 000 €",
                12: "110 000 €",
                13: "120 000 €",
                14: "130 000 €",
                15: "140 000 €",
                16: "150 000 €",
                17: "160 000 €",
                18: "170 000 €",
                19: "180 000 €",
                20: "190 000 €",
                21: "200 000 €"
            }
        },
        contents3: {
            label: "Mercancías",
            values: {
                1: "N/A",
                2: "10 000 €",
                3: "20 000 €",
                4: "30 000 €",
                5: "40 000 €",
                6: "50 000 €",
                7: "60 000 €",
                8: "70 000 €",
                9: "80 000 €",
                10: "90 000 €",
                11: "100 000 €",
                12: "110 000 €",
                13: "120 000 €",
                14: "130 000 €",
                15: "140 000 €",
                16: "150 000 €",
                17: "160 000 €",
                18: "170 000 €",
                19: "180 000 €",
                20: "190 000 €",
                21: "200 000 €"
            }
        },
        coverType: {
            label: "Cuadro médico o Reembolso",
            values: {
                1: "Cuadro médico",
                2: "Reembolso"
            }
        },
        outOfHospitalCare: {
            label: "Asistencia extrahospitalaria"
        },
        hospitalCare: {
            label: "Asistencia hospitalaria"
        },
        dentalCover: {
            label: "Cobertura dental"
        },
        policyholders: {
            label: "Assegurados",
            policyholderSex: {
                label: "Hombre o Mujer",
                values: {
                    1: "Hombre",
                    2: "Mujer"
                }
            },
            policyholderDateOfBirth: {
                label: "Fecha nacimiento"
            }
        },
        customerName: { label: "Nombre" },
        businessName: { label: "Razón social" },
        fiscalId: { label: "NIF o CIF" },
        telephone: { label: "Telefono" },
        email: { label: "Email" },
        profession: {
            label: "Profesión"
        },
        autonomous: {
            label: "Autonomo"
        },
        postCode: {
            label: "Código Postal"
        },
        province: {
            label: "Provincia"
        },
        death: { label: "Fallecimiento" },
        permamentDisablement: { label: "Incapacidad Permanente" },
        doubleAccidentCapital: { label: "Doble capital por accident" },
        tripaAeccidentCapital: { label: "Triple capital por accidente de trafico" },
        seriousDiseases: { label: "Enfermedades Grave" },
        pensions: { label: "Plan de Pensiones" },
        savings: { label: "Seguro de Ahorro" },
        packType: {
            label: "Tipo Pack",
            values: {
                1: "Premium",
                2: "Excellence",
                3: "Personalizado"
            }
        },
        packPersonalised1: { label: "Asistencia jurídica telefónica" },
        packPersonalised2: { label: "Asistencia jurídica presencial - (max 3 consultas por anualidad)" },
        packPersonalised3: { label: "Revisión y redacción de documentos y contratos" },
        packPersonalised4: { label: "Cuestiones administrativas - 3.000 €" },
        packPersonalised5: { label: "Defensa fiscal 3.000 €" },
        packPersonalised6: { label: "Reclamación de daños y perjuicios - 3.000 €" },
        packPersonalised7: { label: "Reclamación de daños al personal asalariado - 3.000 €" },
        packPersonalised8: { label: "Reclamación a clientes por demandas, denuncias o querellas infundadas - 3.000 €" },
        packPersonalised9: { label: "Defensa de derechos sobre el local - 3.000 €" },
        packPersonalised10: { label: "Defensa de la responsabilidad penal - 3.000 €" },
        packPersonalised11: { label: "Defensa penal del personal asalariado - 3.000 €" },
        packPersonalised12: { label: "Subsidio por inhabilitación derivada de sentencia penal" },
        packPersonalised13: { label: "Defensa suplementaria de la responsabilidad civil - 3.000 €" },
        packPersonalised14: { label: "Reclamación en contratos de seguros - 3.000 €" },
        packPersonalised15: { label: "Reclamación en contratos sobre bienes muebles - 3.000 €" },
        packPersonalised16: { label: "Reclamación en contratos de servicios - 3.000 €" },
        packPersonalised17: { label: "Reclamación en contratos de suministros - 3.000 €" },
        packPersonalised18: { label: "Reclamación en cuestiones financieras - Servicio ARAG" },
        packPersonalised19: { label: "Reclamación a contratas v subcontratas - 3.000 €" },
        packPersonalised20: { label: "Reclamación de honorarios y facturas impagadas - 3.000 € (max. 5 reclamaciones/anualidad)" },
        packPersonalised21: { label: "Defensa ante la inspección de trabajo - 3.000 €" },
        packPersonalised22: { label: "Defensa en contratos laborales - 3.000 €" },
        packPersonalised23: {
            label: "Defensa ante reclamaciones por acoso laboral y/o acoso o agresión sexual en el ámbito laboral - 3.000 € Reclamación de prestaciones sociales del profesional o autónomo - 3.000 €"
        },
        packPersonalised24: { label: "Reclamación de prestaciones sociales del profesional o autónomo - 3.000 €" },
        packPersonalised25: { label: "Defensa laboral del trabajador autónomo económicamente dependiente - 3.000 €" },
        packPersonalised26: { label: "Vehículos a motor - 3.000 € excepto ciclomotores y motocicletas 1.500 €" },
        packPersonalised27: { label: "Gastos de peritaje - 1.000 € por anualidad (max. 3 peritajes por anualidad)" }
    };

    let html = '<table style="width: 100%" border="1">';

    for (const [key, values] of Object.entries(body)) {
        let response;
        let subitem = [];
        if (fields[key]) {
            if (!Array.isArray(values)) {
                if (fields[key].values && fields[key].values[values]) {
                    response = fields[key].values[values];
                } else {
                    if (typeof values === "boolean") {
                        response = values ? "Si" : "No";
                    } else {
                        response = values;
                    }
                }
                html += `<tr><td>${fields[key].label}</td><td>${response}</td></tr>`;
            } else {
                for (const value of values) {
                    if (typeof value === "object") {
                        html += `<tr role="row"><th colspan="2"><i>${fields[key].label}</i></th></tr>`;
                        const keys = Object.keys(value);
                        for (const item of keys) {
                            if (fields[key][item].values && fields[key][item].values[value[item]]) {
                                response = `${fields[key][item].values[value[item]]}`;
                            } else {
                                response = `${value[item]}`;
                            }
                            html += `<tr><td>${fields[key][item].label}</td><td>${response}</td></tr>`;
                        }
                    } else {
                        if (fields[key].values && fields[key].values[value]) {
                            subitem.push(`${fields[key].values[value]}`);
                        }
                    }
                }
                if (subitem.length > 0) {
                    html += `<tr><td>${fields[key].label}</td><td>${subitem.join(",")}</td></tr>`;
                }
            }
        }
    }

    return html;
}

export async function submitForm(values, form) {
    const html = buildQuote(values);

    try {
        await fetcher("mailer", {
            method: "POST",
            body: JSON.stringify({ html, form })
        });
    } catch (e) {
        alert(JSON.stringify(e));
    }
}

window.buildQuote = buildQuote;
