import React from "react";
import { withTranslation } from "react-multi-lang";
import { Row, Col, Button } from "antd";
import styled from "styled-components";

import Header from "./Header";
import Footer from "./Footer";

const Wrapper = styled.div`
    padding-bottom: 50px;
`;

const StyledRow = styled(Row)`
    margin: 10px;
`;

class Selector extends React.Component {
    render() {
        const { t } = this.props;
        return (
            <Wrapper>
                <Header />

                <StyledRow>
                    <Col xs={{ span: 24, offset: 0 }} lg={{ span: 8, offset: 8 }} sm={{ span: 24, offset: 0 }}>
                        <Button type="primary" block onClick={() => this.props.history.push("/civil")}>
                            {t("selector.civil")}
                        </Button>

                        <Button type="primary" style={{ marginTop: 10 }} onClick={() => this.props.history.push("/office")} block>
                            {t("selector.office")}
                        </Button>

                        <Button
                            type="primary"
                            style={{ marginTop: 10 }}
                            target="_blank"
                            href="https://klinc.com/contratar-seguro/?MASTER_POLICY_ID=1031360100036181&BRANCH_DEPARTMENT=NA&SELLER_FISCAL_ID=NA"
                            block
                        >
                            {t("selector.devices")}
                        </Button>

                        <Button type="primary" style={{ marginTop: 10 }} onClick={() => this.props.history.push("/cybersecurity")} block>
                            {t("selector.cybersecurity")}
                        </Button>

                        <Button type="primary" style={{ marginTop: 10 }} onClick={() => this.props.history.push("/autonomous")} block>
                            {t("selector.autonomous")}
                        </Button>

                        <Button type="primary" style={{ marginTop: 10 }} onClick={() => this.props.history.push("/legal")} block>
                            {t("selector.legal")}
                        </Button>
                    </Col>
                </StyledRow>
                <Footer />
            </Wrapper>
        );
    }
}

export default withTranslation(Selector);
