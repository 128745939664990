import React from "react";
import PropTypes from "prop-types";
import { Form } from "react-final-form";
import { Button, Steps, Row, Col, Typography } from "antd";
import styled from "styled-components";
import { withTranslation } from "react-multi-lang";

import arrayMutators from "final-form-arrays";

const { Step } = Steps;

const { Text, Title } = Typography;

const StyledSteps = styled(Steps)`
    margin-bottom: 15px;
`;

const Complete = styled.div`
    text-align: center;
`;

class Wizard extends React.Component {
    static propTypes = {
        onSubmit: PropTypes.func.isRequired
    };
    static Page = ({ children, mutators }) => {
        if (typeof children === "function") {
            return children(mutators);
        }

        return children;
    };
    constructor(props) {
        super(props);
        this.state = {
            page: 0,
            values: props.initialValues || {}
        };
    }
    next = values =>
        this.setState(state => ({
            page: Math.min(state.page + 1, this.props.children.length - 1),
            values
        }));

    previous = () =>
        this.setState(state => ({
            page: Math.max(state.page - 1, 0)
        }));

    /**
     * NOTE: Both validate and handleSubmit switching are implemented
     * here because 🏁 Redux Final Form does not accept changes to those
     * functions once the form has been defined.
     */

    validate = values => {
        const activePage = React.Children.toArray(this.props.children)[this.state.page];
        return activePage.props.validate ? activePage.props.validate(values) : {};
    };

    handleSubmit = values => {
        const { children, onSubmit } = this.props;
        const { page } = this.state;
        const isLastPage = page === React.Children.count(children) - 1;
        if (isLastPage) {
            return onSubmit(values);
        } else {
            this.next(values);
        }
    };

    renderSteps(totalSteps) {
        let steps = [];
        for (let i = 1; i <= totalSteps; i += 1) {
            steps.push(<Step size="small" key={i} title={this.props.t("quote.step") + " " + i} />);
        }

        return steps;
    }

    render() {
        const { children, t, isComplete } = this.props;
        const { page, values } = this.state;
        const activePage = React.Children.toArray(children)[page];
        const isLastPage = page === React.Children.count(children) - 1;
        const totalSteps = React.Children.toArray(children).length;

        if (isComplete) {
            return (
                <Complete>
                    <Title level={4}>{t("quote.quoteSuccessTitle")}</Title>
                    <Text>{t("quote.quoteSuccess")}</Text>
                </Complete>
            );
        }
        return (
            <>
                {!this.props.hideWizard && (
                    <Row gutter={8}>
                        <Col xs={0} sm={24}>
                            <StyledSteps size="small" current={this.state.page}>
                                {this.renderSteps(totalSteps)}
                            </StyledSteps>
                        </Col>
                    </Row>
                )}

                <Form
                    initialValues={values}
                    validate={this.validate}
                    onSubmit={this.handleSubmit}
                    mutators={{
                        ...arrayMutators
                    }}
                >
                    {({ handleSubmit, submitting, values, form: { mutators } }) => (
                        <form onSubmit={handleSubmit} className="ant-form-vertical">
                            {<activePage.type {...activePage.props} mutators={mutators} />}
                            <Row gutter={8}>
                                <Col span={12}>
                                    {page > 0 && (
                                        <Button onClick={this.previous} block>
                                            {t("quote.previous")}
                                        </Button>
                                    )}
                                </Col>
                                <Col span={page > 0 ? 12 : 24}>
                                    {!isLastPage && (
                                        <Button type="primary" htmlType="submit" block>
                                            {t("quote.next")}
                                        </Button>
                                    )}
                                    {isLastPage && (
                                        <Button type="primary" htmlType="submit" block disabled={submitting}>
                                            {t("quote.getQuote")}
                                        </Button>
                                    )}
                                </Col>
                            </Row>
                        </form>
                    )}
                </Form>
            </>
        );
    }
}

export default withTranslation(Wizard);
