import React from "react";
import { Field } from "react-final-form";
import Wizard from "./Wizard";
import { withTranslation } from "react-multi-lang";
import { Row, Col, Typography } from "antd";
import styled from "styled-components";

import Select from "./fields/Select";
import Checkbox from "./fields/Checkbox";

import Header from "./Header";
import Footer from "./Footer";

import FinalStep from "./FinalStep";

import { businessTypeOptions, incomeOptions, employeesOptions } from "./options";

import { submitForm } from "./utils";

const { Title } = Typography;

const Wrapper = styled.div`
    padding-bottom: 50px;
`;

const StyledRow = styled(Row)`
    margin: 10px;
`;

class QuoteCyber extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isComplete: false
        };
    }

    onSubmit = async values => {
        try {
            await submitForm(values, "Ciberseguridad");
            this.setState({ isComplete: true });
        } catch (e) {
            alert("error");
        }
    };

    render() {
        const required = value => (value ? undefined : t("quote.required"));

        const { t } = this.props;

        return (
            <Wrapper>
                <Header />

                <StyledRow>
                    <Col xs={{ span: 24, offset: 0 }} lg={{ span: 8, offset: 8 }} sm={{ span: 24, offset: 0 }}>
                        <Wizard
                            onSubmit={this.onSubmit}
                            isComplete={this.state.isComplete}
                            initialValues={{ antiVirus: false, backup: false, cardProcessing: false, intrusion: false, interuption: false, extortion: false }}
                        >
                            <Wizard.Page>
                                <Field
                                    name="businessType"
                                    validate={required}
                                    component={Select}
                                    options={businessTypeOptions(t)}
                                    placeholder={t("quote.selectCategory")}
                                    label={t("quote.businessType")}
                                    mode="multiple"
                                />
                            </Wizard.Page>

                            <Wizard.Page>
                                <Field
                                    name="businessIncome"
                                    validate={required}
                                    component={Select}
                                    options={incomeOptions(t)}
                                    placeholder={t("quote.selectOption")}
                                    label={t("quote.businessIncome")}
                                />
                            </Wizard.Page>

                            <Wizard.Page>
                                <Field
                                    name="numberEmployees"
                                    validate={required}
                                    component={Select}
                                    options={employeesOptions(t)}
                                    placeholder={t("quote.selectOption")}
                                    label={t("quote.numberEmployees")}
                                />
                                <Field
                                    name="websiteSize"
                                    validate={required}
                                    component={Select}
                                    options={[
                                        { value: 1, label: "0-1" },
                                        { value: 2, label: "2" },
                                        { value: 3, label: ">3" }
                                    ]}
                                    placeholder={t("quote.selectOption")}
                                    label={t("quote.websiteSize")}
                                />
                            </Wizard.Page>

                            <Wizard.Page>
                                <Field name="antiVirus" component={Checkbox} type="checkbox" label={t("quote.antiVirus")} />
                                <Field name="backup" component={Checkbox} type="checkbox" label={t("quote.backup")} />
                                <Field name="cardProcessing" component={Checkbox} type="checkbox" label={t("quote.cardProcessing")} />
                                <Field name="intrusion" component={Checkbox} type="checkbox" label={t("quote.intrusion")} />
                            </Wizard.Page>

                            <Wizard.Page>
                                <Field
                                    name="basicGuaranteeData"
                                    validate={required}
                                    component={Select}
                                    options={[
                                        { value: 1, label: "150 000 €" },
                                        { value: 2, label: "300 000 €" },
                                        { value: 3, label: "450 000 €" },
                                        { value: 4, label: "600 000 €" },
                                        { value: 5, label: "1 000 000 €" },
                                        { value: 6, label: "1 500 000 €" },
                                        { value: 7, label: "2 000 000 €" }
                                    ]}
                                    placeholder={t("quote.selectSum")}
                                    label={t("quote.basicGuaranteeData")}
                                />

                                <Title level={4}>{t("quote.optionalItems")}</Title>

                                <Field name="interuption" component={Checkbox} type="checkbox" label={t("quote.interuption")} />
                                <Field name="extortion" component={Checkbox} type="checkbox" label={t("quote.extortion")} />
                            </Wizard.Page>
                            <Wizard.Page>
                                <FinalStep />
                            </Wizard.Page>
                        </Wizard>
                    </Col>
                </StyledRow>
                <Footer />
            </Wrapper>
        );
    }
}

export default withTranslation(QuoteCyber);
