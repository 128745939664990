import React from "react";
import { Field } from "react-final-form";
import Wizard from "./Wizard";
import { withTranslation } from "react-multi-lang";
import { Row, Col, Typography, Popover } from "antd";
import styled from "styled-components";

import { QuestionCircleOutlined } from "@ant-design/icons";

import Select from "./fields/Select";
import Checkbox from "./fields/Checkbox";

import Header from "./Header";
import Footer from "./Footer";

import FinalStep from "./FinalStep";

import { businessTypeOptions, incomeOptions, employeesOptions } from "./options";

import { submitForm } from "./utils";

const { Title } = Typography;

const Wrapper = styled.div`
    padding-bottom: 50px;
`;

const StyledRow = styled(Row)`
    margin: 10px;
`;

const PopoverContent = styled.div`
    width: 200px;
    word-wrap: break-word;
`;

class QuoteCivil extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isComplete: false
        };
    }

    onSubmit = async values => {
        try {
            await submitForm(values, "Responsabilidad Civil");
            this.setState({ isComplete: true });
        } catch (e) {
            alert("error");
        }
    };

    render() {
        const required = value => (value ? undefined : t("quote.required"));

        const { t } = this.props;

        return (
            <Wrapper>
                <Header />

                <StyledRow>
                    <Col xs={{ span: 24, offset: 0 }} lg={{ span: 8, offset: 8 }} sm={{ span: 24, offset: 0 }}>
                        <Wizard
                            onSubmit={this.onSubmit}
                            isComplete={this.state.isComplete}
                            initialValues={{ clientsUSACanda: false, accidentLiability: false, professionalLiability: false, dataLiability: false }}
                        >
                            <Wizard.Page>
                                <Field
                                    name="businessType"
                                    validate={required}
                                    component={Select}
                                    options={businessTypeOptions(t)}
                                    placeholder={t("quote.selectCategory")}
                                    label={t("quote.businessType")}
                                    mode="multiple"
                                />
                            </Wizard.Page>

                            <Wizard.Page>
                                <Field
                                    name="businessIncome"
                                    validate={required}
                                    component={Select}
                                    options={incomeOptions(t)}
                                    placeholder={t("quote.selectOption")}
                                    label={t("quote.businessIncome")}
                                />
                            </Wizard.Page>

                            <Wizard.Page>
                                <Field name="clientsUSACanda" component={Checkbox} type="checkbox" label={t("quote.clientsUSACanda")} />
                                <Field
                                    name="numberEmployees"
                                    validate={required}
                                    component={Select}
                                    options={employeesOptions(t)}
                                    placeholder={t("quote.selectOption")}
                                    label={t("quote.numberEmployees")}
                                />
                            </Wizard.Page>

                            <Wizard.Page>
                                <Field
                                    name="basicGuarantee"
                                    validate={required}
                                    component={Select}
                                    options={[
                                        { value: 1, label: "150 000 €" },
                                        { value: 2, label: "300 000 €" },
                                        { value: 3, label: "600 000 €" },
                                        { value: 4, label: "1 000 000 €" },
                                        { value: 5, label: "1 500 000 €" }
                                    ]}
                                    placeholder={t("quote.selectSum")}
                                    label={t("quote.basicGuarantee")}
                                    extra={t("quote.basicGuaranteeOptions")}
                                />
                                <Title level={4}>{t("quote.optionalItems")}</Title>

                                <Field
                                    name="accidentLiability"
                                    component={Checkbox}
                                    type="checkbox"
                                    label={
                                        <>
                                            {t("quote.accidentLiability")}
                                            <Popover content={<PopoverContent>{t("quote.descriptionAccidents")}</PopoverContent>} title={t("quote.explanation")}>
                                                {""} <QuestionCircleOutlined />
                                            </Popover>
                                        </>
                                    }
                                />

                                <Field
                                    name="professionalLiability"
                                    component={Checkbox}
                                    type="checkbox"
                                    label={
                                        <>
                                            {t("quote.professionalLiability")}
                                            <Popover content={<PopoverContent>{t("quote.descriptionProfessional")}</PopoverContent>} title={t("quote.explanation")}>
                                                {" "}
                                                <QuestionCircleOutlined />
                                            </Popover>
                                        </>
                                    }
                                />

                                <Field
                                    name="dataLiability"
                                    component={Checkbox}
                                    type="checkbox"
                                    label={
                                        <>
                                            {t("quote.dataLiability")}
                                            <Popover content={<PopoverContent>{t("quote.descriptionData")}</PopoverContent>} title={t("quote.explanation")}>
                                                {" "}
                                                <QuestionCircleOutlined />
                                            </Popover>
                                        </>
                                    }
                                />
                            </Wizard.Page>
                            <Wizard.Page>
                                <FinalStep />
                            </Wizard.Page>
                        </Wizard>
                    </Col>
                </StyledRow>
                <Footer />
            </Wrapper>
        );
    }
}

export default withTranslation(QuoteCivil);
