import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import { setDefaultTranslations, setDefaultLanguage } from "react-multi-lang";

import { ConfigProvider } from "antd";

import es from "./translations/es.json";

setDefaultTranslations({ es });
setDefaultLanguage("es");

const customizeRenderEmpty = () => (
    <div style={{ textAlign: "center" }}>
        <span>:(</span>
    </div>
);

ReactDOM.render(
    <React.StrictMode>
        <ConfigProvider renderEmpty={customizeRenderEmpty}>
            <App />
        </ConfigProvider>
    </React.StrictMode>,
    document.getElementById("root")
);
