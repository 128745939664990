export function businessTypeOptions(t) {
    let options = [];
    for (let i = 1; i <= 31; i += 1) {
        options.push({ value: i, label: t(`quote.businessType${i}`) });
    }

    return options;
}

export function incomeOptions(t) {
    let options = [{ value: 1, label: "0 €" }];
    let x = 2;
    for (let i = 10000; i <= 1000000; i += 10000) {
        options.push({ value: x, label: `${i.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ")} €` });
        x++;
    }

    options.push({ value: 102, label: `${t("quote.moreThan")} 1 000 000 €` });

    return options;
}

export function employeesOptions(t) {
    let options = [{ value: 1, label: "0" }];
    for (let i = 2; i <= 20; i += 1) {
        options.push({ value: i, label: i });
    }

    options.push({ value: 22, label: `${t("quote.moreThan")} 20` });

    return options;
}

export function valueOptions() {
    let options = [{ value: 1, label: "N/A" }];
    let x = 2;
    for (let i = 10000; i <= 200000; i += 10000) {
        options.push({ value: x, label: `${i.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ")} €` });
        x++;
    }

    return options;
}
